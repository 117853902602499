<template>
  <div class="container">
    <div class="row d-print-none">
      <div class="col-sm-12">
        <h2>
          Income Report
        </h2>
      </div>
      <div class="col-sm-3 px-1">
       <b-input-group id="form-start-date-group" prepend="Start Date" label-for="form-start-date" class="my-2">
          <b-form-input id="form-start-date" v-model="start_date" required type="date">
          </b-form-input>
        </b-input-group>
      </div>
      <div class="col-sm-3 px-1">
       <b-input-group id="form-end-date-group" prepend="End Date" label-for="form-end-date" class="my-2">
          <b-form-input id="form-end-date" v-model="end_date" required type="date">
          </b-form-input>
        </b-input-group>
      </div>

      <div class="col-sm-6 px-1">
        <b-button variant="secondary" class="button-sm mx-1 my-2" @click="anonomymous = !anonomymous">
          Use {{ anonomymous ? 'Anonymous': 'Personal' }} Names
        </b-button>

        <b-button variant="primary" class="button-sm mx-1 my-2" @click="genReport()">
          Generate Report
        </b-button>

      </div>
    </div>

    <div class="row" v-if="payments.length > 0">
      <h3>Yearly Income Summary By Month</h3>
      <table v-for="(months, year) in payment_totals" :key="year" class="table table-sm table-bordered">
        <tr class="table-active">
          <th> {{ year }}</th>
          <th  class="text-right"> Total:  ${{ payment_sums[year].total / 100 | formatNumber }} </th>
        </tr>
        <tbody v-for="(students, month) in months" :key="month">
          <tr>
            <td> {{ MONTHS[month] }} </td>
            <td class="text-right" style="width: 20%">${{ payment_sums[year][month].total / 100 | formatNumber }} </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row" v-if="payments.length > 0">
      <h3>Monthly Income By Student</h3>
      <table v-for="(months, year) in payment_totals" :key="year" class="table table-sm table-bordered">
        <thead>
        </thead>
        <!--
        <tr>
          <th> {{ year }}</th>
          <th> </th>
        </tr>
        -->
        <tbody v-for="(students, month) in months" :key="month">
          <tr class="table-active">
            <th> {{ MONTHS[month] }} {{ year }}</th>
            <th style="width: 20%" class="text-right">{{ MONTHS[month] }} Total: ${{ payment_sums[year][month].total / 100 | formatNumber }} </th>
          </tr>
          <tr v-for="(student, id) in students" :key="id">

            <td v-if="anonomymous"> Student {{ id }} </td>
            <td v-else> {{ student.name }}</td>
            <td class="text-right"> ${{ student.amount / 100 | formatNumber  }}</td>
          </tr>
        </tbody>
        <!--
        <tr>
          <th></th>
          <th  class="text-right"> {{ year }} Total:  ${{ payment_sums[year].total / 100 | formatNumber }} </th>
        </tr>
        -->
      </table>
    </div>
  </div>
</template>

<script>
import { ApiFactory } from "../api/ApiFactory";
const PaymentsAPI = ApiFactory.get("payments");

const MONTHS = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December'
}
export default {

  data: function(){
    return {
      start_date:  new Date().getFullYear() + '-01-01',
      end_date:  new Date().getFullYear() + '-' + ('0' + (new Date().getMonth()+1)).slice(-2) + '-' + new Date().getDate(),
      payments: [],
      payment_totals: {},
      payment_sums: {},
      anonomymous: true,
      MONTHS: MONTHS
    }
  },

  methods: {
    async genReport(){
     const {data} = await PaymentsAPI.get(this.start_date, this.end_date)
     this.payments = data

     this.payment_totals = {}
     this.payment_sums = {}
     this.payments.forEach((payment) =>{
       var month = String(new Date(payment.week_of).getUTCMonth()+1)
       var year = String(new Date(payment.week_of).getUTCFullYear())
       if (this.payment_totals[year] == undefined){
         this.payment_totals[year] = {}
       }
       if (this.payment_totals[year][month] == undefined){
         this.payment_totals[year][month] = {}
       }

       if (this.payment_sums[year] == undefined){
         this.payment_sums[year] = {
           total: 0
         }
       }
       if (this.payment_sums[year][month] == undefined){
         this.payment_sums[year][month] = {
           total: 0
         }
       }

       var id = null;
       if (payment.student != null){
         id = payment.student.id
       }else{
         id = payment.family.students[0]
         payment.student = {
           name: payment.family.name
         }
       }

       this.payment_sums[year].total += payment.amount
       this.payment_sums[year][month].total += payment.amount
       if (this.payment_totals[year][month][id] == undefined){
          this.payment_totals[year][month][id] = {
            amount: payment.amount,
            name: payment.student.name,
            count: 1
          }
        }else{
          this.payment_totals[year][month][id].amount += payment.amount
          this.payment_totals[year][month][id].count += 1
        }
       
     });

    }

   
  }

}
</script>
