<template>
  <div class="container">
    <div class="row d-print-none">
      <div class="col-sm-12" style="text-align: center">
        <h2>
          Student Awards
        </h2>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
       <b-input-group id="form-start-date-group" prepend="Start Date" label-for="form-start-date" class="my-2">
          <b-form-input id="form-start-date" v-model="start_date" required type="date" @change="loadAwards()">
          </b-form-input>
        </b-input-group>
      </div>
      <div class="col-sm-4">
       <b-input-group id="form-end-date-group" prepend="End Date" label-for="form-end-date" class="my-2">
          <b-form-input id="form-end-date" v-model="end_date" required type="date" @change="loadAwards()">
          </b-form-input>
        </b-input-group>
      </div>

      <div class="col-sm-3">
       <b-input-group id="form-limit-date-group" prepend="Limit" label-for="form-limit" class="my-2">
          <b-form-input id="form-limit" v-model="limit" required type="number" @change="loadAwards()">
          </b-form-input>
        </b-input-group>
      </div>


    </div>

    <hr>

    <div class="row" >
      <div class="col-sm-4">
        <table class="table table-striped table-sm table-hover table-bordered" style="width: 100%">
          <thead class="thead-dark">
            <tr>
              <th>Student</th>
              <th style="text-align: right">Highest Points</th>
            </tr>
          </thead>
          <tbody>
              <tr v-for="student in points" :key="student.id" :class="student.points == highest_points && 'table-info' ">
                <td scope="col" :style="student.points == highest_points ? 'font-weight: bold' : ''"> {{student.name}}</td>
                <td scope="col" style="text-align: right"> {{ student.points }} Points </td>
              </tr>
          </tbody>
        </table>
      </div>
      
      <div class="col-sm-4">
        <table class="table table-striped table-sm table-hover table-bordered" style="width: 100%">
          <thead class="thead-dark">
            <tr>
              <th>Student</th>
              <th style="text-align: right">Most Gold Stars</th>
            </tr>
          </thead>
          <tbody>
              <tr v-for="student in gold_stars" :key="student.id" :class="student.gold == highest_gold && 'table-info' ">
                <td scope="col" :style="student.gold == highest_gold ? 'font-weight: bold' : ''"> {{student.name}}</td>
                <td scope="col" style="text-align: right"> {{ student.gold }} Gold Stars </td>
              </tr>
          </tbody>
        </table>
      </div>

      <div class="col-sm-4">
        <table class="table table-striped table-sm table-hover table-bordered" style="width: 100%">
          <thead class="thead-dark">
            <tr>
              <th>Student</th>
              <th style="text-align: right">Least Absences</th>
            </tr>
          </thead>
          <tbody>
              <tr v-for="student in absences" :key="student.id" :class="student.absences == least_absences && 'table-info' ">
                <td scope="col" :style="student.absences == least_absences ? 'font-weight: bold' : ''"> {{student.name}}</td>
                <td scope="col" style="text-align: right"> {{ student.absences }} Absences</td>
              </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { ApiFactory } from "../api/ApiFactory";
const AwardsAPI = ApiFactory.get("awards");
const RecitalsAPI = ApiFactory.get("recitals")
var _ = require('lodash');

export default {

  data: function(){
    return {
      start_date:  new Date().getFullYear() + '-01-01',
      end_date:  new Date().getFullYear() + '-' + ('0' + (new Date().getMonth()+1)).slice(-2) + '-' + new Date().getDate(),
      gold_stars: [],
      absences: [],
      points: [],

      limit: 15,

      highest_gold: null,
      highest_points: null,
      least_absences: null,
      recitals: []
    }
  },

  created: async function(){
      const {data} = await RecitalsAPI.get()
      this.recitals = _.orderBy(data, ['week_of'], ['desc']); 
  
      if (this.recitals.length > 0){
        this.end_date = this.recitals[0].week_of
      }
      if (this.recitals.length > 1){
        this.start_date = this.recitals[1].week_of
      }
      this.loadAwards()
  },

  methods: {
    async loadAwards(){

      if (!this.start_date || !this.end_date || this.limit <= 0){
        return
      }

     const {data} = await AwardsAPI.get(this.start_date, this.end_date, this.limit)
     this.gold_stars = data['gold'];

     if (this.gold_stars.length >= 1){
       this.highest_gold = this.gold_stars[0]['gold'];
     }
     
     this.absences = data['absences'];
     if (this.absences.length >= 1){
       this.least_absences = this.absences[0]['absences'];
     }
     
     this.points = data['points'];
     if (this.points.length >= 1){
       this.highest_points = this.points[0]['points'];
     }
     
    }
    
  }

}
</script>
